<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="{
      'menu-item-open': hasActiveChildren(' '),
    }"
  >
    <component :is="itemDefault.sub.length === 0 ? 'router-link' : 'a'" :to="to" class="menu-link menu-toggle">
      <i v-bind:class="`menu-icon ${iconClass} text-muted`"></i>
      <span class="menu-text">{{ title }}</span>
      <template v-if="itemDefault.sub.length !== 0">
        <i class="menu-arrow"></i>
      </template>
    </component>
    <!-- <a v-else class="menu-link menu-toggle">
      <i v-bind:class="`menu-icon ${iconClass} text-muted`"></i>
      <span class="menu-text">{{ title }}</span>
      <template v-if="itemDefault.sub.length !== 0">
        <i class="menu-arrow"></i>
      </template>
    </a> -->

    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">{{ title }}</span>
          </span>
        </li>

        <sc-menu-sub v-for="(sub, i) in item.sub" :key="`submenu_${i}`" :title="sub.title" :to="sub.to"></sc-menu-sub>
      </ul>
    </div>
  </li>
</template>

<script>
import ScMenuSub from '@/view/content/widgets/sc-menu-sub.vue'

export default {
  name: 'sc-menu',
  components: {
    ScMenuSub,
  },
  props: {
    title: {
      type: String,
      default: 'Home',
    },
    id: {
      type: Number,
    },
    iconClass: {
      type: String,
      defaul: 'fa fa-home',
    },
    subtitle: {
      type: String,
    },
    to: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
    },
    action: {
      type: Boolean,
    },
  },

  computed: {
    itemDefault: {
      get() {
        if (typeof this.item.sub !== 'undefined') {
          return this.item
        } else {
          let newItem
          newItem = this.item
          newItem.sub = []
          return newItem
        }
      },
    },
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1
    },
  },
}
</script>
