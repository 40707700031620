<template>
  <router-link :to="to" v-slot="{ href, navigate, isActive, isExactActive }">
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item"
      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
    >
      <a :href="href" class="menu-link" @click="navigate">
        <i class="menu-bullet menu-bullet-dot">
          <span></span>
        </i>
        <span class="menu-text">{{ title }} </span>
      </a>
    </li>
  </router-link>
  <!-- <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item"
    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
  >
    <router-link :to="to">
      <i class="menu-bullet menu-bullet-dot">
        <span></span>
      </i>
      <span class="menu-text">{{ title }} </span>
    </router-link>
  </li> -->
</template>
<script>
export default {
  name: 'sc-sub-menu',
  props: {
    title: {
      type: String,
      default: 'Overview',
    },
    to: {
      type: String,
    },
  },
}
</script>
