<template>
  <ul class="menu-nav">
    <sc-menu
      v-for="(item, i) in menu"
      :key="`menu_${i}`"
      :item="item"
      :title="item.title"
      variant="danger"
      :icon-class="item.iconClass"
      :to="item.to"
      :action="true"
    />
    <div class="menu-item menu-item-submenu">
      <b-button variant="outline" class="menu-link menu-toggle bg-variant" @click="onLogout">
        <i v-bind:class="`menu-icon fas fa-sign-out-alt text-muted`"></i>
        <span class="menu-text"> Sign out </span>
      </b-button>
    </div>
  </ul>
</template>

<script>
import ScMenu from '../../content/widgets/sc-menu.vue'
import { LOGOUT } from '../../../core/services/store/auth.module'
import Swal from 'sweetalert2'
import DisclaimerServices from '../../../core/services/disclaimers'
export default {
  name: 'KTMenu',
  data() {
    return {
      menu: [
        {
          title: 'Home',
          iconClass: 'fas fa-home',
          to: '/dashboard',
        },
        {
          title: 'User',
          iconClass: 'fas fa-user-friends ',
          to: '/user',
        },
        {
          title: 'Events',
          iconClass: 'far fa-calendar',
          sub: [
            // { title: 'New', to: '/events/new' },
            // {title: "Pending Scooble Activity",to:"/events/pendingScoobleActivity"},
            // {title: "Active",to:"/events/active"},
            // {title: "Pending Premium User Activity",to:"/events/pendingPremiumUserActivity"},
            // {title: "All Premium User Events",to:"/events/allPremiumUserEvents"},
            { title: 'Overview', to: '/events' },
          ],
        },
        // {
        //   title: 'Maps',
        //   iconClass: 'far fa-map',
        //   sub: [
        //     { title: 'New', to: '/maps/new' },
        //     { title: 'Overview', to: '/maps/overview' },
        //   ],
        // },
        {
          title: 'Sponsors',
          iconClass: 'far fa-star',
          sub: [
            { title: 'New', to: '/sponsors/new' },
            { title: 'Overview', to: '/sponsors/overview' },
          ],
        },
        {
          title: 'Profile',
          iconClass: 'far fa-user',
          to: '/profile',
        },
        // {
        //   title: 'Pages',
        //   iconClass: 'fas fa-file-alt',
        //   sub: [
        //     { title: 'New', to: '/pages/new' },
        //     { title: 'Overview', to: '/pages/overview' },
        //   ],
        // },
        // {
        //   title: 'Contracts',
        //   iconClass: 'fas fa-file-contract',
        //   sub: [
        //     { title: 'New', to: '/contracts/new' },
        //     { title: 'Overview', to: '/contracts/overview' },
        //   ],
        // },
        {
          title: 'Notifications',
          iconClass: 'far fa-envelope',
          sub: [
            { title: 'New', to: '/notifications/new' },
            { title: 'Overview', to: '/notifications/overview' },
          ],
        },
        {
          title: 'Disclaimer',
          iconClass: 'far fa-folder',
          sub: [
            { title: 'New Disclaimer', to: '/disclaimers/newdisclaimer' },
            // { title: 'Data Protection', to: '/disclaimers/dataprotection' },
            // { title: 'Imprint', to: '/disclaimers/imprint' },
            // {
            //   title: 'Terms and Conditions',
            //   to: '/disclaimers/termsandconditions',
            // },
            // {
            //   title: 'Declaration of Consent',
            //   to: '/disclaimers/declarationofconsent',
            // },
          ],
        },
        // {
        //   title: 'Sign out',
        //   iconClass: 'fas fa-sign-out-alt',
        // },
      ],
    }
  },
  components: {
    ScMenu,
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1
    },
    onLogout() {
      // FIXME : alert text değişebilir
      Swal.fire({
        title: 'Sure?',
        text: 'Are you sure you really want to sign out?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#181C32',
        cancelButtonColor: '#c3c3c3',
        confirmButtonText: 'Yes',
      }).then(result => {
        console.log(`result`, result)
        if (result.isConfirmed) {
          this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: 'login' }))
        }
      })
    },
    async getDisclaimers() {
      const { data } = await DisclaimerServices.getAll()
      this.menu.find(v => {
        if (v.title == 'Disclaimer') {
          data.map(data => {
            v.sub.push({
              title: data.titleEN,
              to: `/disclaimers/info-disclaimers/${data._id}`,
            })
          })
        }
      })
    },
  },
  mounted() {
    this.getDisclaimers()
  },
}
</script>
